import { h } from 'preact';
import { Route, Router } from 'preact-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './header';

import Home from '../routes/home';
import Footer from './footer';
import PrivacyPolicy from '../routes/privacy-policy';
import TermsOfUse from '../routes/terms-of-use';
import Y from '../routes/y/index';
import PaymentSuccess from '../routes/payment-success/index';
import PaymentCancelled from '../routes/cancel/index';

const App = () => {
  const isYPage = location.pathname.includes('/y/');
  return (
    <div id="app" className="flex flex-col justify-center items-center w-full">
      <div className={`w-full ${isYPage ? '' : 'bg-slate-100'}`}>
        {!isYPage && <Header />}
        <main>
          <Router>
            <Route path="/" component={Home} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/terms-of-use" component={TermsOfUse} />
            <Route path="/y/:slug/:password" component={Y} />
            <Route path="/y/:slug" component={Y} />
            <Route path="/payment-success" component={PaymentSuccess} />
            <Route path="cancel" component={PaymentCancelled} />
          </Router>
        </main>
        {!isYPage && <Footer />}
      </div>
      <ToastContainer />
    </div>
  );
};

export default App;
