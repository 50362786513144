import { getBrowserLanguage } from './get-browser-lang';

export const getSavedLanguage = () => {
  if (
    typeof window !== 'undefined' &&
    typeof window.localStorage !== 'undefined'
  ) {
    const savedLanguage = window.localStorage.getItem('language');
    return savedLanguage || getBrowserLanguage();
  }

  return getBrowserLanguage();
};
