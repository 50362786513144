import i18n from 'i18next';
import { initReactI18next } from 'preact-i18next';
import { getSavedLanguage } from '../utils/get-saved-lang';

i18n.use(initReactI18next).init({
  lng: getSavedLanguage(),
  fallbackLng: 'pt',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    pt: {
      translation: {
        voltar_para_a_pagina_inicial: 'Voltar para a Página Inicial',
        pagamento_sucesso: 'Pagamento Realizado com Sucesso',
        pagamento_sucesso_mensagem:
          'Logo, você receberá um e-mail com os detalhes para acessar ou editar seu site personalizado.',
        voltar: 'Voltar',
        pagamento_cancelado_mensagem:
          'Parece que você interrompeu o processo de pagamento. Caso tenha repensado sua decisão, estamos aqui para ajudá-lo a finalizar sua compra de forma rápida e segura. Aproveite a oportunidade e se junte a mais de 5 mil casais que confiam em nós e conclua seu pedido agora!',
        pagamento_cancelado: 'Pagamento Cancelado',
        atualizado_com_sucesso: 'Atualizado com sucesso',
        atualizar: 'Atualizar',
        suporte_contato_descricao:
          'Você pode entrar em contato com nosso suporte ao cliente através do e-mail contact@amoreinsieme.site.',
        pagamento_formas_descricao: 'Aceitamos apenas pagamentos via Stripe.',
        tempo_qr_code_descricao:
          'Pagamentos com cartão de crédito ficam prontos na hora.',
        edicao_pagina_descricao:
          'Sim. Assim que você receber o link para a sua página, ele incluirá uma seção de edição que você pode usar para fazer alterações.',
        validade_pagina_descricao:
          'No preço básico sim, 1 ano. No plano avançado, a página personalizada estará disponível para você pelo resto da vida.',
        recebimento_pagina_descricao:
          'Após a conclusão do pagamento, você receberá um QR code para compartilhar com seu parceiro(a) e um link via e-mail para acessar a página.',
        pagina_inclusao_descricao:
          'Sua página personalizada incluirá um contador ao vivo mostrando há quanto tempo vocês estão juntos, uma apresentação de slides com suas fotos e uma mensagem especial de declaração.',
        criacao_pagina_descricao:
          "Para criar uma página personalizada, preencha o formulário com os nomes do casal, a data de início do relacionamento, uma mensagem de declaração e até 7 fotos. Depois, clique no botão 'Criar Casal' e finalize o pagamento.",
        amore_insieme_descricao:
          'Amore Insieme é uma plataforma que permite criar páginas personalizadas de relacionamento para casais. Você pode adicionar fotos, uma mensagem especial e um contador que mostra há quanto tempo vocês estão juntos.',
        remover_fotos: 'Remover fotos',
        adicionar_fotos: 'Adicionar fotos',
        adicionar_imagens: 'Adicionar imagens',
        criar_nosso_site: 'Criar nosso site',
        checklist_para_casais: 'Checklist para casais',
        novidade: 'Novidade:',
        surpreenda_seu_amor: 'Surpreenda o seu amor',
        crie_um_contador:
          'Crie um contador dinâmico do tempo de relacionamento. Preencha o formulário e receba o seu site personalizado + Código QR para compartilhar com o seu amor ❤️',
        um_ano_sem_musica: '1 ano, 3 fotos e sem música - R$19,99',
        pra_sempre_com_musica: 'Para sempre, 7 fotos e com música - R$39,99',
        nome_do_casal: 'Nome do casal:',
        inicio_do_relacionamento: 'Início do relacionamento:',
        mensagem: 'Mensagem:',
        escolher_fotos_1: 'Escolher fotos do casal (Máximo 3)',
        escolher_fotos_2: 'Escolher fotos do casal (Máximo 7)',
        como_vai_ficar: 'Como vai ficar 👇',
        preencher_dados: 'Preencha os dados 📝',
        pagamento: 'Faça o pagamento 💰',
        receber_site: 'Receba o seu site + Código QR no seu e-mail ✉️',
        surpresa: 'Faça uma surpresa para o seu amor ❤️',
        como_funciona: 'Como Funciona',
        faq: 'Perguntas Frequentes (FAQ)',
        o_que_e_amore: 'O que é a Amore Insieme?',
        como_criar_pagina:
          'Como posso criar uma página personalizada na Amore Insieme?',
        inclusao_pagina: 'O que está incluído na minha página personalizada?',
        receber_pagina:
          'Como recebo a minha página personalizada após o pagamento?',
        validade_pagina: 'A página personalizada tem validade?',
        editar_pagina: 'Posso editar a minha página depois de criá-la?',
        custo_pagina: 'Qual é o custo para criar uma página na Amore Insieme?',
        tempo_para_qr_code:
          'Quanto tempo demora a receber o Código QR no seu e-mail?',
        formas_pagamento: 'Quais são as formas de pagamento?',
        contato_suporte:
          'Como posso entrar em contato com o suporte ao cliente?',
        contato_email:
          'Você pode entrar em contato com o nosso suporte ao cliente através do e-mail contact@amoreinsieme.site.',
        quero_fazer_meu_site: 'Quero criar o meu site',
        nome_do_casal_placeholder: 'Nome do Casal',
        mensagem_placeholder: 'Mensagem',
        anos: 'Anos',
        meses: 'Meses',
        dias: 'Dias',
        horas: 'Horas',
        minutos: 'Minutos',
        segundos: 'Segundos',
        todos_os_direitos_reservados: 'Todos os direitos reservados',
        política_de_privacidade: 'Política de Privacidade',
        termos_de_uso: 'Termos de Uso',
        juntos: 'Juntos',
        musica_youtube_opcional: 'Música (Youtube opcional)',
      },
    },
    en: {
      translation: {
        voltar_para_a_pagina_inicial: 'Back to Home',
        pagamento_sucesso: 'Payment successful',
        pagamento_sucesso_mensagem:
          'Soon, you will receive an email with the details to access or edit your personalized website.',
        voltar: 'Back',
        pagamento_cancelado_mensagem:
          "It looks like you interrupted the payment process. If you've reconsidered your decision, we're here to help you complete your purchase quickly and securely. Take advantage of this opportunity and join over 5,000 couples who trust us. Complete your order now!",
        pagamento_cancelado: 'Payment Cancelled',
        atualizado_com_sucesso: 'Updated successfully',
        atualizar: 'Update',
        suporte_contato_descricao:
          'You can contact our customer support via email at contact@amoreinsieme.site.',
        pagamento_formas_descricao: 'We only accept payments via Stripe.',
        tempo_qr_code_descricao:
          'Credit card payments are processed instantly.',
        edicao_pagina_descricao:
          'Yes. Once you receive the link to your page, it will include an editing section you can use to make changes.',
        validade_pagina_descricao:
          'In the basic price, yes, 1 year. In the advanced plan, the personalized page will be available to you for a lifetime.',
        recebimento_pagina_descricao:
          'After completing the payment, you will receive a QR code to share with your partner and a link via email to access the page.',
        pagina_inclusao_descricao:
          'Your personalized page will include a live counter showing how long you have been together, a slideshow with your photos, and a special declaration message.',
        criacao_pagina_descricao:
          "To create a personalized page, fill out the form with the couple's names, the relationship start date, a declaration message, and up to 7 photos. Then, click the 'Create Couple' button and complete the payment.",
        amore_insieme_descricao:
          'Amore Insieme is a platform that allows you to create personalized relationship pages for couples. You can add photos, a special message, and a timer that shows how long you are together.',
        remover_fotos: 'Remove photos',
        adicionar_fotos: 'Add photos',
        adicionar_imagens: 'Add images',
        criar_nosso_site: 'Create our site',
        checklist_para_casais: 'Checklist for couples',
        novidade: 'New:',
        surpreenda_seu_amor: 'Surprise your love',
        crie_um_contador:
          'Create a dynamic relationship timer. Fill out the form and receive your personalized site + QR Code to share with your love ❤️',
        um_ano_sem_musica: '1 year, 3 photos and no music - $19.99',
        pra_sempre_com_musica: 'Forever, 7 photos and with music - $39.99',
        nome_do_casal: 'Couple’s name:',
        inicio_do_relacionamento: 'Start of relationship:',
        mensagem: 'Message:',
        escolher_fotos_1: 'Choose couple’s photos (Maximum 3)',
        escolher_fotos_2: 'Choose couple’s photos (Maximum 7)',
        como_vai_ficar: 'How it will look 👇',
        preencher_dados: 'Fill in the details 📝',
        pagamento: 'Make the payment 💰',
        receber_site: 'Receive your site + QR Code via email ✉️',
        surpresa: 'Surprise your love ❤️',
        como_funciona: 'How It Works',
        faq: 'Frequently Asked Questions (FAQ)',
        o_que_e_amore: 'What is Amore Insieme?',
        como_criar_pagina:
          'How can I create a personalized page on Amore Insieme?',
        inclusao_pagina: 'What is included in my personalized page?',
        receber_pagina: 'How do I receive my personalized page after payment?',
        validade_pagina: 'Does the personalized page have an expiration?',
        editar_pagina: 'Can I edit my page after creating it?',
        custo_pagina: 'What is the cost to create a page on Amore Insieme?',
        tempo_para_qr_code:
          'How long does it take to receive the QR Code by email?',
        formas_pagamento: 'What payment methods are accepted?',
        contato_suporte: 'How can I contact customer support?',
        contato_email:
          'You can contact our customer support via email at contact@amoreinsieme.site.',
        quero_fazer_meu_site: 'I want to create my site',
        nome_do_casal_placeholder: 'Couple’s name',
        mensagem_placeholder: 'Message',
        anos: 'years',
        meses: 'months',
        dias: 'days',
        horas: 'hours',
        minutos: 'minutes',
        segundos: 'seconds',
        todos_os_direitos_reservados: 'All rights reserved',
        política_de_privacidade: 'Privacy Policy',
        termos_de_uso: 'Terms of Use',
        juntos: 'Together',
        musica_youtube_opcional: 'Music (Youtube optional)',
      },
    },
    de: {
      translation: {
        voltar_para_a_pagina_inicial: 'Zurück zur Startseite',
        pagamento_sucesso: 'Zahlung erfolgreich',
        pagamento_sucesso_mensagem:
          'In Kürze erhalten Sie eine E-Mail mit den Details, um auf Ihre personalisierte Website zuzugreifen oder sie zu bearbeiten.',
        voltar: 'Zurück',
        pagamento_cancelado_mensagem:
          'Es scheint, dass Sie den Zahlungsprozess unterbrochen haben. Falls Sie Ihre Entscheidung überdacht haben, sind wir hier, um Ihnen zu helfen, Ihren Kauf schnell und sicher abzuschließen. Nutzen Sie die Gelegenheit und schließen Sie sich über 5.000 Paaren an, die uns vertrauen. Schließen Sie jetzt Ihre Bestellung ab!',
        pagamento_cancelado: 'Zahlung Abgelehnt',
        atualizado_com_sucesso: 'Erfolgreich aktualisiert',
        atualizar: 'Aktualisieren',
        suporte_contato_descricao:
          'Sie können unseren Kundenservice per E-Mail unter contact@amoreinsieme.site kontaktieren.',
        pagamento_formas_descricao:
          'Wir akzeptieren nur Zahlungen über Stripe.',
        tempo_qr_code_descricao:
          'Kreditkartenzahlungen werden sofort verarbeitet.',
        edicao_pagina_descricao:
          'Ja. Sobald Sie den Link zu Ihrer Seite erhalten, enthält er einen Bearbeitungsbereich, den Sie für Änderungen verwenden können.',
        validade_pagina_descricao:
          'Zum Basispreis ja, 1 Jahr. Im erweiterten Plan ist die benutzerdefinierte Seite für immer verfügbar.',
        recebimento_pagina_descricao:
          'Nach Abschluss der Zahlung erhalten Sie einen QR-Code, den Sie mit Ihrem Partner teilen können, und einen Link per E-Mail, um auf die Seite zuzugreifen.',
        pagina_inclusao_descricao:
          'Ihre benutzerdefinierte Seite enthält einen Live-Zähler, der anzeigt, wie lange Sie zusammen sind, eine Diashow Ihrer Fotos und eine besondere Erklärung.',
        criacao_pagina_descricao:
          "Um eine personalisierte Seite zu erstellen, füllen Sie das Formular mit den Namen des Paares, dem Startdatum der Beziehung, einer Erklärung und bis zu 7 Fotos aus. Klicken Sie dann auf die Schaltfläche 'Paar erstellen' und schließen Sie die Zahlung ab.",
        amore_insieme_descricao:
          'Amore Insieme ist eine Plattform, die es Ihnen ermöglicht, personalisierte Beziehungsseiten für Paare zu erstellen. Sie können Fotos, eine besondere Nachricht und einen Timer hinzufügen, der zeigt, wie lange Sie zusammen sind.',
        remover_fotos: 'Fotos entfernen',
        adicionar_fotos: 'Fotos hinzufügen',
        adicionar_imagens: 'Bilder hinzufügen',
        criar_nosso_site: 'Erstellen Sie unsere Seite',
        checklist_para_casais: 'Checkliste für Paare',
        novidade: 'Neu:',
        surpreenda_seu_amor: 'Überraschen Sie Ihre Liebe',
        crie_um_contador:
          'Erstellen Sie einen dynamischen Beziehungstimer. Füllen Sie das Formular aus und erhalten Sie Ihre personalisierte Seite + QR-Code, um ihn mit Ihrer Liebe zu teilen ❤️',
        um_ano_sem_musica: '1 Jahr, 3 Fotos und keine Musik - 19,99 €',
        pra_sempre_com_musica: 'Für immer, 7 Fotos und mit Musik - 39,99 €',
        nome_do_casal: 'Name des Paares:',
        inicio_do_relacionamento: 'Beginn der Beziehung:',
        mensagem: 'Nachricht:',
        escolher_fotos_1: 'Wählen Sie Fotos des Paares aus (Maximal 3)',
        escolher_fotos_2: 'Wählen Sie Fotos des Paares aus (Maximal 7)',
        como_vai_ficar: 'So wird es aussehen 👇',
        preencher_dados: 'Füllen Sie die Daten aus 📝',
        pagamento: 'Zahlung tätigen 💰',
        receber_site: 'Erhalten Sie Ihre Seite + QR-Code per E-Mail ✉️',
        surpresa: 'Überraschen Sie Ihre Liebe ❤️',
        como_funciona: 'So funktioniert es',
        faq: 'Häufig gestellte Fragen (FAQ)',
        o_que_e_amore: 'Was ist Amore Insieme?',
        como_criar_pagina:
          'Wie kann ich eine personalisierte Seite bei Amore Insieme erstellen?',
        inclusao_pagina: 'Was ist in meiner personalisierten Seite enthalten?',
        receber_pagina:
          'Wie erhalte ich meine personalisierte Seite nach der Zahlung?',
        validade_pagina: 'Hat die personalisierte Seite eine Ablaufzeit?',
        editar_pagina: 'Kann ich meine Seite nach der Erstellung bearbeiten?',
        custo_pagina:
          'Wie hoch sind die Kosten für die Erstellung einer Seite bei Amore Insieme?',
        tempo_para_qr_code:
          'Wie lange dauert es, bis ich den QR-Code per E-Mail erhalte?',
        formas_pagamento: 'Welche Zahlungsmethoden werden akzeptiert?',
        contato_suporte: 'Wie kann ich den Kundenservice kontaktieren?',
        contato_email:
          'Sie können unseren Kundenservice per E-Mail unter contact@amoreinsieme.site kontaktieren.',
        quero_fazer_meu_site: 'Ich möchte meine Seite erstellen',
        nome_do_casal_placeholder: 'Name des Paares',
        mensagem_placeholder: 'Nachricht',
        anos: 'Jahre',
        meses: 'Monate',
        dias: 'Tage',
        horas: 'Stunden',
        minutos: 'Minuten',
        segundos: 'Sekunden',
        todos_os_direitos_reservados: 'Alle Rechte vorbehalten',
        política_de_privacidade: 'Datenschutzrichtlinie',
        termos_de_uso: 'Nutzungsbedingungen',
        juntos: 'Zusammen',
        musica_youtube_opcional: 'Musik (Youtube optional)',
      },
    },
    fr: {
      translation: {
        voltar_para_a_pagina_inicial: "Retourner à la page d'accueil",
        pagamento_sucesso: 'Paiement reussi',
        pagamento_sucesso_mensagem:
          'Bientôt, vous recevrez un e-mail avec les détails pour accéder à votre site personnalisé ou le modifier.',
        voltar: 'Retour',
        pagamento_cancelado_mensagem:
          'Il semble que vous ayez interrompu le processus de paiement. Si vous avez reconsidéré votre décision, nous sommes là pour vous aider à finaliser votre achat rapidement et en toute sécurité. Profitez de cette opportunité et rejoignez plus de 5 000 couples qui nous font confiance. Finalisez votre commande dès maintenant!',
        pagamento_cancelado: 'Paiement Annulé',
        atualizado_com_sucesso: 'Mis à jour avec succès',
        atualizar: 'Mettre à jour',
        suporte_contato_descricao:
          'Vous pouvez contacter notre service client par e-mail à contact@amoreinsieme.site.',
        pagamento_formas_descricao:
          "Nous n'acceptons que les paiements via Stripe.",
        tempo_qr_code_descricao:
          'Les paiements par carte de crédit sont traités immédiatement.',
        edicao_pagina_descricao:
          'Oui. Une fois que vous recevez le lien vers votre page, il comprendra une section de modification que vous pouvez utiliser pour effectuer des changements.',
        validade_pagina_descricao:
          'Au prix de base oui, 1 an. Dans le plan avancé, la page personnalisée sera disponible pour vous toute votre vie.',
        recebimento_pagina_descricao:
          'Après le paiement, vous recevrez un code QR à partager avec votre partenaire et un lien par e-mail pour accéder à la page.',
        pagina_inclusao_descricao:
          'Votre page personnalisée comprendra un compteur en direct indiquant depuis combien de temps vous êtes ensemble, un diaporama avec vos photos et un message spécial de déclaration.',
        criacao_pagina_descricao:
          "Pour créer une page personnalisée, remplissez le formulaire avec les noms du couple, la date de début de la relation, un message de déclaration et jusqu'à 7 photos. Ensuite, cliquez sur le bouton 'Créer le couple' et complétez le paiement.",
        amore_insieme_descricao:
          'Amore Insieme est une plateforme qui vous permet de créer des pages de relation personnalisées pour les couples. Vous pouvez ajouter des photos, un message spécial et un compteur qui montre combien de temps vous êtes ensemble.',
        remover_fotos: 'Supprimer les photos',
        adicionar_fotos: 'Ajouter des photos',
        adicionar_imagens: 'Ajouter des images',
        criar_nosso_site: 'Créer notre site',
        checklist_para_casais: 'Liste de contrôle pour les couples',
        novidade: 'Nouveauté :',
        surpreenda_seu_amor: 'Surprenez votre amour',
        crie_um_contador:
          'Créez un compteur de relation dynamique. Remplissez le formulaire et recevez votre site personnalisé + QR Code à partager avec votre amour ❤️',
        um_ano_sem_musica: '1 an, 3 photos et sans musique - 19,99 €',
        pra_sempre_com_musica:
          'Pour toujours, 7 photos et avec musique - 39,99 €',
        nome_do_casal: 'Nom du couple :',
        inicio_do_relacionamento: 'Début de la relation :',
        mensagem: 'Message :',
        escolher_fotos_1: 'Choisir les photos du couple (Maximum 3)',
        escolher_fotos_2: 'Choisir les photos du couple (Maximum 7)',
        como_vai_ficar: 'Comment ça va ressembler 👇',
        preencher_dados: 'Remplir les informations 📝',
        pagamento: 'Effectuer le paiement 💰',
        receber_site: 'Recevez votre site + QR Code par e-mail ✉️',
        surpresa: 'Faites une surprise à votre amour ❤️',
        como_funciona: 'Comment ça fonctionne',
        faq: 'Questions Fréquemment Posées (FAQ)',
        o_que_e_amore: "Qu'est-ce qu'Amore Insieme ?",
        como_criar_pagina:
          'Comment puis-je créer une page personnalisée sur Amore Insieme ?',
        inclusao_pagina: 'Que comprend ma page personnalisée ?',
        receber_pagina:
          'Comment puis-je recevoir ma page personnalisée après le paiement ?',
        validade_pagina:
          "La page personnalisée a-t-elle une date d'expiration ?",
        editar_pagina: "Puis-je modifier ma page après l'avoir créée ?",
        custo_pagina:
          'Quel est le coût pour créer une page sur Amore Insieme ?',
        tempo_para_qr_code:
          'Combien de temps faut-il pour recevoir le QR Code par e-mail ?',
        formas_pagamento: 'Quels sont les moyens de paiement acceptés ?',
        contato_suporte: 'Comment puis-je contacter le service client ?',
        contato_email:
          'Vous pouvez contacter notre service client par e-mail à contact@amoreinsieme.site.',
        quero_fazer_meu_site: 'Je veux créer mon site',
        nome_do_casal_placeholder: 'Nom du couple',
        mensagem_placeholder: 'Message',
        anos: 'Années',
        meses: 'Mois',
        dias: 'Jours',
        horas: 'Heures',
        minutos: 'Minutes',
        segundos: 'Secondes',
        todos_os_direitos_reservados: 'Tous droits réservés',
        política_de_privacidade: 'Politique de confidentialité',
        termos_de_uso: "Conditions d'utilisation",
        juntos: 'Ensemble',
        musica_youtube_opcional: 'Musique (Youtube optionnel)',
      },
    },
    it: {
      translation: {
        voltar_para_a_pagina_inicial: 'Torna alla home',
        pagamento_sucesso: 'Pagamento riuscito',
        pagamento_sucesso_mensagem:
          "Presto riceverai un'email con i dettagli per accedere o modificare il tuo sito personalizzato.",
        voltar: 'Torna indietro',
        pagamento_cancelado_mensagem:
          'Sembra che tu abbia interrotto il processo di pagamento. Se hai cambiato idea, siamo qui per aiutarti a completare il tuo acquisto in modo rapido e sicuro. Approfitta di questa opportunità e unisciti a oltre 5.000 coppie che si fidano di noi. Completa il tuo ordine adesso!',
        pagamento_cancelado: 'Pagamento Annullato',
        atualizado_com_sucesso: 'Aggiornato con successo',
        atualizar: 'Aggiorna',
        suporte_contato_descricao:
          "Puoi contattare il nostro servizio clienti via e-mail all'indirizzo contact@amoreinsieme.site.",
        pagamento_formas_descricao: 'Accettiamo solo pagamenti tramite Stripe.',
        tempo_qr_code_descricao:
          'I pagamenti con carta di credito sono elaborati immediatamente.',
        edicao_pagina_descricao:
          'Sì. Una volta ricevuto il link alla tua pagina, includerà una sezione di modifica che puoi utilizzare per apportare modifiche.',
        validade_pagina_descricao:
          'Nel prezzo base sì, 1 anno. Nel piano avanzato, la pagina personalizzata sarà disponibile per tutta la vita.',
        recebimento_pagina_descricao:
          'Dopo aver completato il pagamento, riceverai un codice QR da condividere con il tuo partner e un link via e-mail per accedere alla pagina.',
        pagina_inclusao_descricao:
          'La tua pagina personalizzata includerà un contatore in tempo reale che mostra da quanto tempo state insieme, una presentazione di diapositive con le tue foto e un messaggio speciale di dichiarazione.',
        criacao_pagina_descricao:
          "Per creare una pagina personalizzata, compila il modulo con i nomi della coppia, la data di inizio della relazione, un messaggio di dichiarazione e fino a 7 foto. Poi, clicca sul pulsante 'Crea Coppia' e completa il pagamento.",
        amore_insieme_descricao:
          'Amore Insieme è una piattaforma che ti consente di creare pagine personalizzate per coppie. Puoi aggiungere foto, un messaggio speciale e un timer che mostra da quanto tempo state insieme.',
        remover_fotos: 'Rimuovere foto',
        adicionar_fotos: 'Aggiungere foto',
        adicionar_imagens: 'Aggiungere immagini',
        criar_nosso_site: 'Crea il nostro sito',
        checklist_para_casais: 'Checklist per coppie',
        novidade: 'Novità:',
        surpreenda_seu_amor: 'Sorprendi il tuo amore',
        crie_um_contador:
          'Crea un timer dinamico della relazione. Compila il modulo e ricevi il tuo sito personalizzato + Codice QR da condividere con il tuo amore ❤️',
        um_ano_sem_musica: '1 anno, 3 foto e senza musica - 19,99 €',
        pra_sempre_com_musica: 'Per sempre, 7 foto e con musica - 39,99 €',
        nome_do_casal: 'Nome della coppia:',
        inicio_do_relacionamento: 'Inizio della relazione:',
        mensagem: 'Messaggio:',
        escolher_fotos_1: 'Scegli foto della coppia (Massimo 3)',
        escolher_fotos_2: 'Scegli foto della coppia (Massimo 7)',
        como_vai_ficar: 'Come apparirà 👇',
        preencher_dados: 'Compila i dati 📝',
        pagamento: 'Effettua il pagamento 💰',
        receber_site: 'Ricevi il tuo sito + Codice QR via e-mail ✉️',
        surpresa: 'Fai una sorpresa al tuo amore ❤️',
        como_funciona: 'Come Funziona',
        faq: 'Domande Frequenti (FAQ)',
        o_que_e_amore: "Che cos'è Amore Insieme?",
        como_criar_pagina:
          'Come posso creare una pagina personalizzata su Amore Insieme?',
        inclusao_pagina: 'Cosa è incluso nella mia pagina personalizzata?',
        receber_pagina:
          'Come ricevo la mia pagina personalizzata dopo il pagamento?',
        validade_pagina: 'La pagina personalizzata ha una scadenza?',
        editar_pagina: 'Posso modificare la mia pagina dopo averla creata?',
        custo_pagina: 'Qual è il costo per creare una pagina su Amore Insieme?',
        tempo_para_qr_code:
          'Quanto tempo ci vuole per ricevere il Codice QR via e-mail?',
        formas_pagamento: 'Quali metodi di pagamento sono accettati?',
        contato_suporte: 'Come posso contattare il servizio clienti?',
        contato_email:
          "Puoi contattare il nostro servizio clienti via e-mail all'indirizzo contact@amoreinsieme.site.",
        quero_fazer_meu_site: 'Voglio creare il mio sito',
        nome_do_casal_placeholder: 'Nome della coppia',
        mensagem_placeholder: 'Messaggio',
        anos: 'Anni',
        meses: 'Mesi',
        dias: 'Giorni',
        horas: 'Ore',
        minutos: 'Minuti',
        segundos: 'Secondi',
        todos_os_direitos_reservados: 'Tutti i diritti riservati',
        política_de_privacidade: 'Politica sulla privacy',
        termos_de_uso: 'Termini di utilizzo',
        juntos: 'Insieme',
        musica_youtube_opcional: 'Musica (Youtube opzionale)',
      },
    },
  },
});

export default i18n;
